<template>
  <div>
    <div>
      <el-button  size="small" type="primary" @click="handleEdit">
        审核
      </el-button>
      <el-button @click="back">
        返 回
      </el-button>
    </div>
    <div v-html="pageHtml" class="statement-read" />
    <!-- 弹窗, 新增 / 修改 -->
    <edit-dialog v-if="editVisible" ref="editDialog" @refreshData="back"></edit-dialog>
  </div>
</template>

<script>
import { PreviewByContent } from '@/api/enterprise/statement'
import EditDialog from './version-examine-edit.vue'

export default {
  components: {
    EditDialog,
  },
  data() {
    return {
      editVisible: false,
      pageH: `${document.body.clientHeight}px`,
      accessToken: localStorage.getItem('accessToken'),
      iframeSrc: '',
      pageHtml: '',
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        productNames: '',
        statementId: 0,
        title: '',
        id: 0,
      },
    }
  },
  created() {
    this.getParams()
    this.previewByContent()
  },
  methods: {
    getParams() {
      this.queryParams = this.$route.query
    },
    previewByContent() {
      PreviewByContent(this.queryParams.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.pageHtml = resData.data
        }
      })
    },
    back() {
      const pageParams = {
        pageNo: this.queryParams.pageNo,
        pageSize: this.queryParams.pageSize,
        productNames: this.queryParams.productNames,
        statementId: this.queryParams.statementId,
        title: this.queryParams.title,
      }
      this.$router.push({
        path: '/statement-version',
        query: pageParams,
      })
    },
    handleEdit() {
      this.editVisible = true
      this.$nextTick(() => {
        this.$refs.editDialog.init(this.queryParams.id)
      })
    },
  },
}
</script>

<style lang="scss">
 .statement-read{
   width: 1200px;
   margin: 20px auto;
   table{
     width: 100%;
     table-layout: fixed;
     box-sizing: border-box;
     border-spacing: 0;
     page-break-inside: avoid!important;
     border-collapse: collapse;
   }
   td,th{
     box-sizing: border-box;
     border: 1px solid #666666;
     page-break-inside: avoid!important;
   }
 }

</style>
