<template>
  <el-dialog
      :title="dataForm.id ? '编辑' : '新增'"
      :visible.sync="dialogVisible"
      width="620px"
      @close="dialogClose"
  >
    <el-form
        v-loading="loading"
        ref="form"
        :model="dataForm"
        label-width="120px"
        :rules="dataRule"
    >
      <el-form-item
          label="审核结果"
          prop="examineResult"
      >
        <el-select v-model="dataForm.examineResult" clearable>
          <el-option v-for="(item,index) in examineResultOptions" :key="index" :label="item.label"
                     :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          label="理由"
          prop="message">
        <el-input type="textarea" v-model="dataForm.message" placeholder="理由"></el-input>
      </el-form-item>
    </el-form>
    <div
        slot="footer"
        class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
          type="primary"
          :disabled="loading"
          @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { queryProductSelect } from '@/api/enterprise/product'
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      dataForm: {
        versionId: 0,
        examineResult: undefined,
        message: '',
      },
      examineResultOptions: [
        { label: '通过', value: 1 },
        { label: '驳回', value: 2 },
      ],
    }
  },
  computed: {
    dataRule() {
      return {
        examineResult: [
          { required: true, message: '审核结果不能为空。', trigger: 'blur' },
        ],
        message: [
          { required: true, message: '理由不能为空。', trigger: 'blur' },
        ],
      }
    },
  },
  created() {
    this.queryProductSelect()
  },
  methods: {
    init(id) {
      this.dialogVisible = true
      this.dataForm.versionId = Number(id)
    },
    queryProductSelect() {
      queryProductSelect().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productList = resData.data
        }
      })
    },
    dialogClose() {
      this.dataForm = {
        versionId: 0,
        examineResult: undefined,
        message: '',
      }
    },
    submitForm: debounce(function () {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$http.post('/privacy/statement/version/examine', this.dataForm).then(({ data: res }) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.dialogVisible = false
                  this.loading = false
                  this.$emit('refreshData')
                },
              })
            }
          }).catch(() => {
            this.loading = false
          })
        }
        this.loading = false
      })
    }, 1000, { leading: true, trailing: false }),
  },
}
</script>
